@import "@/styles/base/typography";

.container {
  padding: var(--space-size-2) var(--space-size-2-5);
  background-color: var(--color-neutral-01);
  margin: 0 0 var(--space-size-1-5) 0;
  max-height: 20rem;
  transition: max-height 1s ease-in;
  overflow: hidden;
  position: relative;

  &.active {
    box-shadow: 0 var(--space-size-1-5) var(--space-size-3) 0
      var(--color-shadow);
    border: 0.2rem solid var(--color-neutral-05);
    border-radius: 0.3rem;
    max-height: 200rem;
    transition: max-height 1s ease-in;
    z-index: 1;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.marginBottom {
    margin-bottom: var(--space-size-3);
  }
}

.titleBox {
  display: flex;
  align-items: center;
}

.index {
  @include body-bold;
  color: var(--color-neutral-01);
  width: var(--space-size-3);
  height: var(--space-size-3);
  background-color: var(--color-neutral-10);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0 var(--space-size-1-5) 0 0;

  &.active {
    background-color: var(--color-primary);
  }
}

.title {
  @include heading-l-bold;
  color: var(--color-neutral-10);
  padding: var(--space-size-1-5) 0;

  &.active {
    color: var(--color-primary);
  }
}

.search {
  width: 36rem;
  margin: 0 0 0 auto;
}

.counter {
  @include body;
  color: var(--color-neutral-10);
  padding: 0 var(--space-size-2) 0 0;
}

.btnContainer {
  padding: 0 var(--space-size-2) 0 0;
}
