@import "@/styles/base/typography";

.spinnerWrapper {
  display: flex;
  justify-content: center;
}

.skeletonWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 2rem;
}
