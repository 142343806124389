@mixin heading-xxl {
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

@mixin heading-xxl-bold {
  @include heading-xxl;
  font-weight: 700;
}

@mixin heading-xl {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}

@mixin heading-xl-bold {
  @include heading-xl;
  font-weight: 700;
}

@mixin heading-l {
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}

@mixin heading-l-bold {
  @include heading-l;
  font-weight: 700;
}

@mixin heading-m {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}

@mixin heading-m-bold {
  @include heading-m;
  font-weight: 700;
}

@mixin heading-s {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

@mixin heading-s-bold {
  @include heading-s;
  font-weight: 700;
}

@mixin heading-xs {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

@mixin heading-xs-bold {
  @include heading-xs;
  font-weight: 700;
}

@mixin body {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}

@mixin body-bold {
  @include body;
  font-weight: 700;
}

@mixin body-s {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

@mixin body-s-bold {
  @include body-s;
  font-weight: 700;
}

@mixin body-xs {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

@mixin body-xs-bold {
  @include body-xs;
  font-weight: 700;
}

@mixin body-xxs {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.035rem;
}

@mixin body-xxs-bold {
  @include body-xxs;
  font-weight: 700;
}
