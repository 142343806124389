@import "@/styles/base/typography";

.container {
  position: relative;
}

.inputWrapper {
  border: 0.15rem solid var(--color-neutral-05);
  border-radius: 0.3rem;
  padding: 1rem var(--space-size-1-5);
  background-color: var(--color-neutral-03);
  display: flex;
  align-items: center;
  max-width: 36rem;
  transition: border 0.2s ease-in;
  position: relative;
  margin: 0 0 0 auto;

  &.focused {
    border: 0.15rem solid var(--color-primary);
    transition: border 0.2s ease-in;
  }
}

.iconContainer {
  position: relative;
  display: inline-block;
  margin: 0 1rem 0 0;
  height: var(--space-size-3);
}

.input {
  @include body;
  color: var(--color-neutral-10);
  padding: 0;
  border: 0;
  background-color: var(--color-neutral-03);
  outline: 0;
  width: calc(100% - 3.4rem);
}

.lengthErrorBox {
  position: absolute;
  margin-top: var(--space-size-1);
  display: flex;
  align-items: flex-end;
  color: var(--color-error);
  @include body-xs;
}

.alertIcon {
  width: var(--space-size-2);
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  background-color: var(--color-neutral-01);
  border: 0.2rem solid var(--color-neutral-05);
  margin: var(--space-size-0-5) 0 0 0;
  position: absolute;
  width: 100%;
  box-shadow: 0 var(--space-size-1-5) var(--space-size-3) 0 var(--color-shadow);
}

.listItem {
  padding: 1rem var(--space-size-2-5);
  border-bottom: 0.2rem solid var(--color-neutral-05);

  &:last-of-type {
    border: 0;
  }
}

.listBtn {
  color: var(--color-neutral-12);
  @include body-s-bold;
  cursor: pointer;
}

.highlight {
  background-color: #e6e9ed;
  border-radius: 0.4rem;
}
