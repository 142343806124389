@import "@/styles/base/typography";

.overlay {
  background-color: var(--color-neutral-01);
  opacity: 0.5;
  height: 0;
  width: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  visibility: hidden;

  &.visible {
    visibility: visible;
    height: 100%;
    width: 100%;
  }
}

.wrapper {
  position: fixed;
  right: var(--space-size-8);
  bottom: 18.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
}

.button {
  background-color: var(--color-primary);
  border-radius: 50%;
  width: var(--space-size-8);
  height: var(--space-size-8);
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  display: block;
  width: var(--space-size-5);
  height: var(--space-size-5);
}

.chip {
  @include body-xs-bold;
  line-height: 1;
  color: var(--color-primary);
  background-color: var(--color-tonal);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
