@import "@/styles/base/typography";

.seriesCode {
  color: var(--color-primary);
  @include body-bold;
}

.descriptionText {
  color: var(--color-neutral-10);
  @include body-s;
  overflow: hidden;
}

.moreInfo {
  display: flex;
  justify-content: center;
  color: var(--color-secondary);
  @include body-s-bold;

  a {
    color: var(--color-secondary);
    text-decoration: underline;
  }

  &.selected {
    align-items: center;
    color: var(--color-success);
  }
}

.textWrapper {
  padding: 2rem var(--space-size-4);
}

.paragraph {
  @include body;
  color: var(--color-neutral-10);

  &:first-of-type {
    margin: 0 0 var(--space-size-4);
  }
}

.footer {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
