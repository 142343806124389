@import "@/styles/base/typography";

.container {
  background-color: var(--color-neutral-02);
  padding: 4.5rem;
  border-radius: 0.3rem;
}

.link {
  background-color: var(--color-neutral-01);
  border-radius: 0.3rem;
  padding: var(--space-size-2);
  width: 100%;
  color: var(--color-primary);
  @include body-bold;
  display: flex;
  align-items: center;
}

.iconContainer {
  display: inline-block;
  width: var(--space-size-3);
  height: var(--space-size-3);

  &:first-of-type {
    margin: 0 var(--space-size-1-5) 0 0;
  }

  &:last-of-type {
    margin: 0 0 0 auto;
  }
}
