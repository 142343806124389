@import "@/styles/base/typography";

.container {
  background-color: var(--color-neutral-01);
  border: 0.2rem solid var(--color-border-01);
  border-radius: 0.6rem;
  box-shadow: 0 var(--space-size-1-5) var(--space-size-3) 0 var(--color-shadow);

  margin: 0 0 var(--space-size-1) 0;
  height: 0;
  width: 0;
  transform: scale(0);
  transform-origin: right bottom;
  transition:
    transform 0.3s ease-in,
    height 0.3s linear 0.3s,
    width 0.3s linear 0.3s;

  &.open {
    height: 100%;
    width: 41.8rem;
    transition: transform 0.3s ease-in;
    transform: scale(1);
  }
}

.header {
  background-color: var(--color-primary);
  padding: var(--space-size-1-5);
  border-radius: 0.6rem 0.6rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @include heading-xs-bold;
  color: var(--color-neutral-01);
  text-transform: uppercase;
  width: calc(100% - var(--space-size-3));
  text-align: center;
}

.closeBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mainContent {
  padding: var(--space-size-2-5) var(--space-size-2-5) 2.8rem
    var(--space-size-2-5);
}

.paragraph {
  @include body-s;
  color: var(--color-neutral-10);
  margin: 0 0 var(--space-size-1);
}

.box {
  background-color: var(--btn-secondary-hover);
  padding: var(--space-size-2);
  border-radius: 0.6rem;
}

.boldParagraph {
  @include body-xs-bold;
  color: var(--color-neutral-10);
  text-transform: uppercase;
}

.list {
  margin: var(--space-size-1) 0 0 0;
}

.listItem {
  background-color: var(--color-neutral-01);
  border-radius: 0.6rem;
  border: 0.2rem solid var(--color-neutral-05);
  padding: 1rem var(--space-size-2-5);
  color: var(--color-primary);
  @include body-bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin: 0 0 var(--space-size-1);
  }
}

.itemParagraph {
  width: calc(100% - 3.2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.version {
  color: var(--color-neutral-10);
  @include body-s;
  max-width: 7rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
  width: 100%;
}

.selected {
  background-color: var(--color-neutral-01);
  border-radius: 0.6rem;
  border: 0.2rem solid var(--color-neutral-05);
  padding: 1rem var(--space-size-2-5);
  color: var(--color-neutral-10);
  @include body-bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--space-size-1) 0 var(--space-size-2);
}

.btn {
  margin: var(--space-size-2) auto 0 auto;
}
