.spinnerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--space-size-2-5) 0;
}

.table thead {
  border-radius: 0 0.3rem 0 0;
}

.skeletonWrapper {
  display: flex;
  flex-direction: column;
}

.skeletonBtn {
  margin: var(--space-size-3) auto var(--space-size-2);
}

.btnContainer {
  margin: var(--space-size-3) 0 var(--space-size-2);
  display: flex;
  justify-content: center;
}
