@import "@/styles/base/typography";

.container {
  background-color: var(--color-neutral-03);
}

.row {
  display: flex;
  align-items: center;
  border: 0.1rem solid var(--color-neutral-05);
  padding: var(--space-size-2-5) var(--space-size-2);
  @include body-s;
  color: var(--color-neutral-10);
}

.iconContainer {
  margin: 0 1rem 0 0;
}

.subtitlesContainer {
  padding: 0.5rem var(--space-size-1-5);
  color: var(--color-neutral-10);
  @include body-xs-bold;
  background-color: var(--color-neutral-03);
  border-right: 0.1rem solid var(--color-neutral-05);
  border-left: 0.1rem solid var(--color-neutral-05);
  display: flex;
  text-transform: uppercase;

  &.borderTop {
    border-top: 0.1rem solid var(--color-neutral-05);
  }
}

.series {
  flex-basis: 15%;
}

.description {
  text-transform: uppercase;
  flex-basis: 55%;
}
