@import "@/styles/base/typography";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.acceptBtnBox {
  display: flex;
  align-items: center;
}

.greyBtn {
  color: var(--color-neutral-10);
  margin-right: 3rem;
  @include body-s-bold;
}

.disabled {
  opacity: 0.35;
  pointer-events: none;
}

.button {
  height: var(--space-size-5);
}
