@import "@/styles/base/typography";

.series {
  color: var(--color-primary);
  @include body-bold;
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.added {
  color: var(--color-success);
  margin-left: 0.5rem;
  @include body-s;
  text-transform: capitalize;
}

.description {
  color: var(--color-neutral-10);
  @include body-s;
}

.version {
  color: var(--color-neutral-10);
  @include body-s;
}

.btnChecked {
  color: var(--color-success);
  @include body-s-bold;
}
