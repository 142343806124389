@import "@/styles/base/typography";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 var(--space-size-3);
}

.title {
  color: var(--color-neutral-10);
  align-self: flex-start;
  margin: var(--space-size-2-5) 0;
  @include body-bold;
}

.licenseContainer {
  height: 60.8rem;
  margin-bottom: 2rem;
  border: 0.1rem solid var(--color-neutral-05);
  border-radius: var(--space-size-1);
  display: flex;
}

.sidebar {
  width: 19.6rem;
  background-color: #eeeff1;
  border-right: 0.1rem solid var(--color-neutral-05);
  border-radius: var(--space-size-1) 0 0 var(--space-size-1);

  div:first-child {
    border-radius: var(--space-size-1) 0 0 0;
  }
}

.licenseBox {
  width: calc(100% - 19.6rem);
  padding: var(--space-size-10) 3.2rem 2.6rem 4.15rem;
  color: var(--color-neutral-10);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  @include body;
  line-height: var(--space-size-3);

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-neutral-01);
  }

  &::-webkit-scrollbar-thumb {
    height: 3.9rem;
    background: var(--color-neutral-06);
    border-radius: var(--space-size-1);
  }
}

.skeletonWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 2rem;
}

.skeletonBtns {
  display: flex;
  justify-content: space-between;
}
