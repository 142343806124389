@import "@/styles/base/typography";

.container {
  box-shadow: none;
}

.tabBtn {
  color: var(--color-neutral-10);
  padding: var(--space-size-2);
  @include heading-s-bold;
  text-transform: capitalize;
  cursor: pointer;
}

.cubeOneBtn[data-state="active"] {
  color: var(--color-neutral-01);
  background-color: var(--color-primary);
  border-radius: var(--space-size-1-5) 0 0 0;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 60%;
    height: 1.5rem;
    width: 0.3rem;
    background-color: var(--color-tertiary);
    transform: rotate(90deg);
    transform-origin: center;
  }
}

.cubeTwoBtn[data-state="active"] {
  color: var(--color-neutral-01);
  background-color: var(--color-primary);
  border-radius: 0 var(--space-size-1-5) 0 0;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 60%;
    height: 1.5rem;
    width: 0.3rem;
    background-color: var(--color-tertiary);
    transform: rotate(90deg);
    transform-origin: center;
  }
}
