@import "@/styles/base/typography";

.sidebarItem {
  background-color: var(--color-neutral-03);
  padding: var(--space-size-1);
  border-bottom: 0.1rem solid var(--color-neutral-05);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconText {
  display: flex;
  align-items: center;
}

.icon {
  flex: 0 0 1.4rem;
  display: flex;
  align-items: center;
}

.check {
  background-color: var(--color-neutral-06);
  border-radius: 50%;

  &.active {
    background-color: var(--color-success);
  }
}

.text {
  color: var(--color-primary);
  margin-left: 1.1rem;
  @include body-xs-bold;
}

.highlighted {
  background-color: var(--color-neutral-01);
}
