@import "@/styles/base/typography";

.container {
  width: 74.5rem;
  padding: var(--space-size-2-5) var(--space-size-4);
}

.text {
  color: var(--color-neutral-10);
  margin-bottom: var(--space-size-2-5);
  @include body;

  &:first-of-type {
    margin-bottom: var(--space-size-2-5);
  }

  &:nth-of-type(2) {
    margin-bottom: var(--space-size-5);
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
