@import "@/styles/base/typography";

.container {
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: var(--space-size-2);
  width: var(--space-size-2);
  position: relative;

  &.onlyLabel {
    width: auto;
    height: auto;

    .label {
      @include body-s-bold;
      color: var(--color-neutral-01);
      padding: var(--space-size-1) var(--space-size-1-5);
      background-color: var(--color-primary);
      border-radius: var(--space-size-1-5) 0.3rem var(--space-size-1-5) 0.3rem;
      background-color: var(--color-primary);
      border-radius: var(--space-size-1-5) 0.3rem var(--space-size-1-5) 0.3rem;
    }

    .checkmark {
      display: none;
    }
  }
  &.similLink {
    width: auto;
    height: auto;

    .label {
      @include body-s-bold;
      color: var(--color-secondary);
      text-decoration: underline;
    }

    .checkmark {
      display: none;
    }
  }
}

.label {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-transform: capitalize;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 0;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: var(--color-neutral-01);
  width: var(--space-size-2);
  height: var(--space-size-2);
  border: 0.1rem solid var(--color-primary);
  border-radius: 0.3rem;
}

.checkbox:checked ~ .checkmark {
  background-color: var(--color-secondary);
  border: 0.1rem solid var(--color-secondary);
  transition: all 0.1s linear;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 0.3rem;
  top: 0.4rem;
  width: 0.8rem;
  height: 0.5rem;
  border: solid var(--color-neutral-01);
  border-width: 0 0 0.2rem 0.2rem;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
