@import "@/styles/base/typography";

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--space-size-4) 0 var(--space-size-7);
}

.button {
  width: 24rem;
}
